.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {

  .container-sm,
  .container {
    max-width: 540px;
  }
}

@media (min-width: 768px) {

  .container-md,
  .container-sm,
  .container {
    max-width: 720px;
  }
}

@media (min-width: 992px) {

  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {

  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {

  .container-xxl,
  .container-xl,
  .container-lg,
  .container-md,
  .container-sm,
  .container {
    max-width: 1320px;
  }
}

.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) * -0.5);
  margin-left: calc(var(--bs-gutter-x) * -0.5);
}

.row>* {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col-6 {
  width: 50%;
}

.col-12 {
  width: 100%;
}

@import url("https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@100;300;400;500;700;800&display=swap");

.why_us_headings {
  font-family: "M Plus Rounded 1c", sans-serif;
  font-weight: 700;
}

.why_us_head_text {
  font-weight: 600;
  /* padding-bottom:20px; */
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .head-text {
    max-width: 100%;
  }

  .container {
    width: 100%;
    display: block;
  }

  .why_us_headings {
    font-size: 20px;
    padding-bottom: 20px;
  }

  .col-6 {
    width: 100%;
  }

  .why_us_head_text {
    font-size: 25px;
    /* background-color: red; */
  }

  .glitch {
    text-align: center !important;
    font-size: 45px !important;
  }

  .robot {
    width: 130px;
  }

  .ast-img {
    display: none;
  }

  .homePage__services__item__title {
    font-size: 20px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .why_us_headings {
    font-size: 20px;
    padding-bottom: 20px;
  }

  .head-text {
    max-width: 600px !important;
  }

  .glitch {
    text-align: center !important;
    font-size: 55px !important;
  }

  /* Images */
  .robot {
    width: 190px;
    margin-left:15px;
  }

  .why_us_head_text {
    font-size: 25px;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .head-text {
    max-width: 500px !important;
    font-size: 18px !important;
    padding: 0px 10px;
    text-align: start;
  }

  .robot {
    width: 190px;
  }

  .welcome {
    text-align: center !important;
    font-size: 59px !important;
    /* padding: 0px 0px !important; */
  }

  .glitch {
    text-align: center !important;
    font-size: 60px !important;
  }

  .homePage__services__item__title {
    font-size: 20px !important;
  }

  .homePage__section .first__content__name {
    max-width: 357px;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .head-text {
    max-width: 400px !important;
    text-align: start;
  }

  .robot {
    width: 190px;
  }

  .homePage__services__item__title {
    font-size: 20px !important;
  }

  .welcome {
    font-size: 65px !important;
  }

  .glitch {
    text-align: center !important;
    font-size: 70px !important;
  }

  .homePage__section .first__content__name {
    max-width: 415px;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .head-text {
    max-width: 454px !important;
  }

  .homePage__services__item__title {
    font-size: 15px;
  }

  .homePage__section .first__content__name {
    max-width: 410px;
  }

  .welcome {
    font-size: 70px !important;
  }
}

@import url("https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c&display=swap");

.why_us_2nd_text {
  color: black !important;
  font-family: "M PLUS Rounded 1c", sans-serif;
  /* padding-inline-end: 20px; */
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-0,
  .gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
  .gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
  .gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
  .gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
  .gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
  .gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
  .gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3,
  .gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4,
  .gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4,
  .gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5,
  .gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5,
  .gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-0,
  .gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
  .gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
  .gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
  .gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
  .gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
  .gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
  .gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3,
  .gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4,
  .gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4,
  .gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5,
  .gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5,
  .gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto>* {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1>* {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2>* {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3>* {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4>* {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5>* {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6>* {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-0,
  .gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
  .gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
  .gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
  .gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
  .gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
  .gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
  .gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3,
  .gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4,
  .gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4,
  .gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5,
  .gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5,
  .gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}



@media only screen and (max-width: 300px) {

  .con_img {
    width: 80%;
    margin-left: 20px;
    visibility: hidden;
  }

  .Mobile_Robo{
    width: 180px;
    position: absolute;
    top: 67%;
    right: 0;
    z-index: -1;

  }

  .aboutUs__first__title--wrap {
    font-size: 15px !important;
  }

}



/* Extra small devices (phones, 600px and down) */
@media only screen and (min-width: 300px) {
  .withColumn__img .rectangle {
    right: -25px !important;
  }

  .contact_img {
    display: none;
  }

  .aboutUs__first__title--wrap {
    font-size: 30px !important;
  }
  .Mobile_Robo{
    width: 180px;
    position: absolute;
    bottom: 188px;
    right: 0;
    z-index: -1;
    
  }


  .con_img_div {
    /* background-color: rgba(255,0,0,0.2); */
    position: absolute;
    width: 100%;
    display: flex;
    justify-content: start;
  }

  .con_img {
    width: 50%;
    margin-left: 70px;
    visibility: hidden;
    /* margin-top: 100px; */
    /* position: absolute; */
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .withColumn__img .rectangle {
    right: -10px !important;
  }

  .contact_img {
    display: none;
  }

  .Mobile_Robo{
    width: 180px;
    position: absolute;
    bottom: 188px;
    right: 0;
    z-index: -1;
    display: none;
    
  }

  .contact_elli {
    display: none;
  }

  .aboutUs__first__title--wrap {
    font-size: 50px !important;
  }

  .con_img_div {
    /* background-color: rgba(255,0,0,0.2); */
    position: absolute;
    width: 100%;
    display: flex;
    top: 65%;
    /* justify-content: ce; */
  }

  .con_img {
    width: 40%;
    /* margin-left: 100px; */
    position: absolute;
    right: 20%;
    top: 580px;
    display: none;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .withColumn__img .rectangle {
    right: -40px !important;
    max-width:200px;
  }

  .head__img {
    animation: none !important;
    width: 100% !important;
    max-width: 450px;
  }

  .contact_img {
    display: none;
  }

  .aboutUs__first__title--wrap {
    font-size: 60px !important;
    z-index: 5;
  }

  .con_img_div {
    /* background-color: rgba(255,0,0,0.2); */
    position: absolute;
    width: 100%;
    display: flex;
    /* top: 65%; */
    /* justify-content: ce; */
  }

  .con_img {
    width: 300px;
    /* margin-left: 100px; */
    position: absolute;
    left: 35%;
    top: 680px;
    
    z-index: 2;
    /* top:-10%; */
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .withColumn__img .rectangle {
    right: -35px !important;
    max-width: 257px;

  }

  .contact_img {
    display: block;
  }

  .contact_elli {
    display: block;
  }

  .aboutUs__first__title--wrap {
    font-size: 70px !important;
    z-index: 5;
  }
}

@media only screen and (min-width: 1024px) {
  .contact_elli {
    width: 30% !important;
    top: 648px;
  }

  .Mobile_Robo{
    width: 271px;
    position: absolute;
    top: 142px;
    right: 0;
    z-index: -1;
    display: block;
    
  }

  .con_img_div {
    /* background-color: rgba(255,0,0,0.2); */
    position: absolute;
    width: 100%;
    display: flex;
    top: 215px;
    /* justify-content: ce; */
  }

  .con_img {
    width: 400px;
    top: 100px;
    /* margin-left: 100px; */
    position: absolute;
    right: -9%;
    left: unset !important;
    /* top:-10%; */
  }

  .contact_elli {
    width: 350px !important;
    z-index: 49 !important;
    right: -33% !important;
    top: 588px !important;
  }

  .my-line {
    padding-right: 118px;
  }

  .footer--wrap {
    z-index: -1;
  }


}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .aboutUs__first__title--wrap {
    font-size: 75px !important;
  }


  .Mobile_Robo{
    width: 271px;
    position: absolute;
    top: 160px;
    right: 0;
    z-index: -1;
    display: block;  
  }


  .contact_img {
    width: 230px;
    /* margin-top: -150px !important;     */
    /* margin-left:100px; */
  }

  .contact_img_section {
    width: 100vw;
    /* background-color: red; */
    display: flex;
    align-items: center;
  }



  .con_img_div {
    /* background-color: rgba(255,0,0,0.2); */
    position: absolute;
    width: 100%;
    display: flex;
    top: 27%;
    /* justify-content: ce; */
  }


  .con_img {
    width: 400px;
    top: 103px;
    /* margin-left: 100px; */
    position: absolute;
    right: -8%;
    left: unset !important;
    /* top:-10%; */
  }

  .contact_elli {
    width: 350px !important;
    z-index: 49 !important;
    top: 596px !important;
    right: -28% !important;
  }

  .my-line {
    padding-right: 115px;
  }

}

@media only screen and (min-width: 1360px) {
  .con_img_div {
    /* background-color: rgba(255,0,0,0.2); */
    position: absolute;
    width: 100%;
    display: flex;
    top: 15%;
    /* justify-content: ce; */
  }

  .con_img {
    width: 400px;
    top: 110px;
    /* margin-left: 100px; */
    position: absolute;
    right: -7%;
    left: unset !important;
    /* top:-10%; */
  }

  .contact_elli {
    width: 350px !important;
    z-index: 49 !important;
    top: 596px !important;
    right: -26% !important;
  }

  .my-line {
    padding-right: 0px;
  }

}

@media only screen and (min-width: 1500px) {
  .con_img_div {
    /* background-color: rgba(255,0,0,0.2); */
    position: absolute;
    width: 100%;
    display: flex;
    top: 22%;
    /* justify-content: end; */
  }



  .con_img {
    width: 475px;
    top: 96px;
    /* margin-left: 100px; */
    position: absolute;
    right: -6%;
    left: unset !important;
    /* top:-10%; */
  }

  .contact_elli {
    width: 384px !important;
    z-index: 49 !important;
    top: 628px !important;
    right: -26% !important;
  }

  .my-line {
    padding-right: 0px;
  }
}


@media only screen and (min-width:1680px) {


  .con_img {
    width: 475px;
    top: 96px;
    /* margin-left: 100px; */
    position: absolute;
    right: -6%;
    left: unset !important;
    /* top:-10%; */
  }

  .contact_elli {
    width: 384px !important;
    z-index: 49 !important;
    top: 637px !important;
    right: -24% !important;
  }

  .my-line {
    padding-right: 0px;
  }
}

@media only screen and (min-width: 1760px) {
  .con_img_div {
    /* background-color: rgba(255,0,0,0.2); */
    position: absolute;
    width: 100%;
    display: flex;
    top: 22%;
    /* justify-content: end; */
  }





  .con_img {
    width: 501px;
    top: 100px;
    /* margin-left: 100px; */
    position: absolute;
    right: -5%;
    left: unset !important;
    /* top:-10%; */
  }

  .contact_elli {
    width: 369px !important;
    z-index: 49 !important;
    top: 655px !important;
    right: -21% !important;
  }

  .my-line {
    padding-right: 0px;
  }
}


@media(min-width:1980px){
  .Mobile_Robo{
    width: 271px;
    position: absolute;
    top: 220px;
    right: 0;
    z-index: -1;
    display: block;
    
  }
}


.contact_img {
  width: 230px;
  margin-top: -1px;
}

.aboutUs__team__column .withColumn__img .rectangle {
  right: 10px !important;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .aboutUs__team__column .withColumn__img .rectangle {
    right: 0px !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .aboutUs__team__column .withColumn__img .rectangle {
    right: 0px !important;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .aboutUs__team__column .withColumn__img .rectangle {
    right: -4px !important;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .aboutUs__team__column .withColumn__img .rectangle {
    right: -6px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
/* @media only screen and (min-width: 1200px) {} */

.homePage .head {
  position: relative !important;
  width: 100% !important;
  max-width: 319px !important;
}

.head.mounted .head__img {
  animation-name: wakeUp !important;
  animation-iteration-count: infinite !important;
  animation-play-state: running !important;
  animation-timing-function: ease-in-out !important;
  animation-direction: alternate !important;
  transform: translateY(15px) rotate(0.6deg) !important;
}



@keyframes wakeUp {
  0% {
    transform: translateY(15px) rotate(0.6deg);
  }

  to {
    transform: translateY(-3px) rotate(-1deg);
  }
}

.buy_hosting_btn {
  font-size: 12px !important;
  padding: 8px 47px !important;
}

/* 
 @media only screen and (max-width:300px){
  .works_text{
    width:50%;
  }

  .works_img{
    width:50%;
  }

  .works_text{
    width: 50%;
  }


} */
/* 
@media only screen and (min-width:300px){
  .works_text{
    width:70%;
  }

  .works_img{
    width:70%;
  }
  .works_text{
    width: 70%;
  }
}  */


@font-face {
  font-family: Nodle;
  src: url('../../assets/fonts/NOLDE_REGULAR.TTF');
}


/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .buy_hosting_btn {
    /* font-size: 12px !important;
    padding: 8px 50px !important;
    display: block; */
    display: none;
  }

  /* 
  .works_text{
    width:70%;
  } */

  .brand__name {
    font-size: 23px;
    color: white;
  }

  /* 
  .works_img{
    width:71%;
  } */

  .footer_logo {
    width: 100px;
  }


  .brand__name__footer {
    font-size: 30px !important;
    font-weight: 500;
    /* font-family: Nodle;
    letter-spacing: 5px; */
  }

  .logo_header {
    width: 100px;
  }

  .header__logo__part-1 {
    width: 46px;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .brand__name {
    font-size: 28px;
    color: white;
  }

  .logo_header {
    width: 150px;
  }

  .header__logo__part-1 {
    width: 50px;
  }

  .footer_logo {
    width: 130px;
  }

  .brand__name__footer {
    font-size: 30px;
    font-weight: 500;
    /* letter-spacing: 5px; */
    /* font-family: Nodle; */
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .brand__name {
    font-size: 30px;
    color: white;
  }

  .square_why_us {
    margin-top: 220px !important;
    right: 200px !important;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .square_why_us {
    margin-top: 200px !important;
    right: 450px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .square_why_us {
    margin-top: 200px !important;
    right: 450px !important;
  }
}

@import url('https://fonts.googleapis.com/css2?family=PT+Serif+Caption&display=swap');


@import url('https://fonts.googleapis.com/css2?family=M+PLUS+1p:wght@300&display=swap');


.hide {
  display: none;
}

.trinko-why-us {
  position: absolute;
  width: 100px;
  top: 344px;
  right: 75px;
}

.cylinder-why-us {
  position: absolute;
  width: 80px;
  top: 1125px;
  right: none;
}

.myElipse {
  position: absolute;
  right: -329px;
  width: 305px;
  bottom: 136px;
  animation: rotateEllipse 10s infinite linear;
  transform: translate(-50%, -50%)
}

.cy-ab {
  position: absolute;
  bottom: 100px !important;
  right: 320px !important;
  animation-delay: 1s;
  animation-duration: 2s;
}

.oval-ab-us {
  width: 100px;
}

.arg-about-us {
  width: 249px !important;
  position: absolute;
  top: 84px;
  left: 137px;
}

.aboutUs__team__column .withColumn__img {
  width: 232px;
}

.aboutUs__result--wrap .ellipse {
  z-index: 2;
  position: absolute;
  animation: rotateEllipse 10s infinite linear;
  width: 429px;
  transform: translate(-50%, -50%);
  bottom: -558px;
  right: -190px;
}

@media (max-width:600px) {
  .trinko-why-us {
    top: 786px;
  }

  .cy-ab {
    display: none;
  }

  .aboutUs__team__column .withColumn__img {
    width: 200px;
  }

  .arg-about-us {
    top: 73px !important;
    left: -19px !important;
    max-width: 227px !important;
  }

}

@media (min-width:600px) {
  .trinko-why-us {
    top: 680px;
  }

  .myElipse {
    bottom: 136px;
  }

  .cy-ab {
    display: none;
  }

  .arg-about-us {
    top: 73px !important;
    left: -19px !important;
    max-width: 227px !important;
  }

}

@media (min-width:768px) {
  .trinko-why-us {
    top: 380px;
    right: 40px;
  }

  .cylinder-why-us {
    top: 650px;
    right: none;
  }

  .myElipse {
    bottom: 215px;
  }

  .cy-ab {
    display: block;
    right: 250px !important;
  }

  .arg-about-us {
    top: 100px !important;
    left: -19px !important;
    max-width: 227px !important;
  }

}

@media (min-width:900px) {
  .trinko-why-us {
    top: 360px;
    right: 40px;
  }

  .cylinder-why-us {
    top: 650px;
    right: none;
  }

}

@media (min-width:1016px) {
  .trinko-why-us {
    top: 340px;
    right: 40px;
  }

  .cylinder-why-us {
    top: 695px;
    right: none;
  }

  .myElipse {
    bottom: 90px;
  }
}

@media (min-width:1234px) {
  .trinko-why-us {
    top: 330px;
    right: 40px;
  }
}


.wrapper {
  width: 100%;
}

.about_astro_size {
  width: 500px;
}


.about_astro {
  position: absolute;
  right: -1%;
  width: 500px;
}

@media (max-width:451px) {

  .about_astro_size {
    width: 50px;
  }

  .about_astro {
    display: none;
  }


}



@media (min-width:451px) {


  .about_astro {
    position: absolute;
    right: -1%;
    top: 35%;
    width: 350px;
  }
}




@media (min-width:600px) {

  .about_astro_size {
    width: 500px;
  }


  .about_astro {
    position: absolute;
    right: -1%;
    top: 35%;
    width: 350px;
  }
}


@media (min-width:600px) {

  .about_astro_size {
    width: 350px;
  }


  .about_astro {
    position: absolute;
    right: -1%;
    top: 35%;
    width: 350px;
  }
}



@media (min-width:768px) {

  .about_astro_size {
    width: 700px;
  }


  .about_astro {
    position: absolute;
    right: -1%;
    top: 5%;
    width: 480px;
  }
}


.text-white {
  color: white !important;
}




@media (max-width:600px) {

  .mobile-hide {
    display: none;
  }
}

@media (min-width:600px) {

  .desktop-hide {
    display: none;
  }
}


@media (max-width:767px) {
  .mob-hide{
    display: none !important;
  }
}

@media (min-width:767px) {
  .tab-hide{
    display: none !important;
  }
}

@media (min-width:1024px){
  .desk-hide{
    display: none !important;
  }
} 


